import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { motion, AnimatePresence } from 'framer-motion';
import { CloudArrowUpIcon, DocumentTextIcon, CheckCircleIcon, PhotoIcon } from '@heroicons/react/24/outline';
import DOMPurify from 'dompurify';

const MAX_UPLOAD_SIZE = parseInt(process.env.REACT_APP_MAX_UPLOAD_SIZE || '250', 10);
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL || 'https://fact.scdesign.eu/api';

const stageColors = [
  'from-blue-400 to-blue-500',
  'from-purple-400 to-purple-500',
  'from-pink-400 to-pink-500'
];

function App() {
  const [file, setFile] = useState(null);
  const [instagramLink, setInstagramLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);
  const [stage, setStage] = useState(0);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = useCallback((event) => {
    const selectedFile = event.target.files[0];
    handleFile(selectedFile);
  }, []);

  const handleFile = useCallback((selectedFile) => {
    if (selectedFile && selectedFile.size > MAX_UPLOAD_SIZE * 1024 * 1024) {
      setError(`File size should not exceed ${MAX_UPLOAD_SIZE} MB`);
    } else {
      setFile(selectedFile);
      setError(null);
    }
  }, []);

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleFile(e.dataTransfer.files[0]);
    }
  }, [handleFile]);

  const handleLinkChange = useCallback((event) => {
    setInstagramLink(event.target.value);
    setFile(null);
  }, []);

  const handleSubmit = useCallback(async (event) => {
    event.preventDefault();
    if (!file && !instagramLink) {
      setError('Please upload a file or provide an Instagram link.');
      return;
    }

    setLoading(true);
    setError(null);
    setResult(null);
    setStage(1);

    const formData = new FormData();
    file ? formData.append('file', file) : formData.append('url', instagramLink);

    try {
      const response = await axios.post(`${API_BASE_URL}/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          if (percentCompleted === 100) setStage(2);
        }
      });

      setStage(3);
      setResult(response.data);
    } catch (error) {
      console.error('API Error:', error.response?.data);
      setError(error.response?.data?.detail || 'An error occurred');
    } finally {
      setLoading(false);
      setStage(0);
    }
  }, [file, instagramLink]);

  const formatText = useCallback((text) => {
    if (!text) return null;
    return text.split('\n')
      .filter(p => p.trim() !== '')
      .map((paragraph, index) => <p key={index} className="mb-4">{paragraph}</p>);
  }, []);

  const getFactCheckStatus = useCallback((factCheck) => {
    if (!factCheck) return { status: 'UNKNOWN', color: 'text-yellow-400' };
    
    const lowerCaseCheck = factCheck.toLowerCase();
    
    if (lowerCaseCheck.includes('mostly accurate')) return { status: 'MOSTLY ACCURATE', color: 'text-green-400' };
    if (lowerCaseCheck.includes('mostly inaccurate')) return { status: 'MOSTLY INACCURATE', color: 'text-red-400' };
    if (lowerCaseCheck.includes('mixed')) return { status: 'MIXED', color: 'text-yellow-400' };
    if (lowerCaseCheck.includes('inconclusive')) return { status: 'INCONCLUSIVE', color: 'text-gray-400' };
    
    return { status: 'UNKNOWN', color: 'text-yellow-400' };
  }, []);

  const extractStatusFromHtml = useCallback((html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, 'text/html');
    const resultElement = doc.querySelector('.result');
    return resultElement ? resultElement.textContent : '';
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-purple-50 p-8 relative overflow-hidden">
      {stage > 0 && (
        <div className="absolute top-0 left-0 w-full h-2 bg-gray-200">
          {[1, 2, 3].map((s) => (
            <motion.div
              key={s}
              className={`absolute top-0 left-0 h-full bg-gradient-to-r ${stageColors[s-1]}`}
              initial={{ width: 0 }}
              animate={{ width: stage >= s ? '33.33%' : 0 }}
              transition={{ duration: 1, ease: "easeInOut" }}
              style={{ left: `${(s-1) * 33.33}%` }}
            />
          ))}
        </div>
      )}
      <AnimatePresence>
        <motion.div
          key={loading ? 'loading' : 'content'}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          exit={{ opacity: 0, y: -20 }}
          transition={{ duration: 0.5 }}
          className="max-w-4xl mx-auto bg-white bg-opacity-90 rounded-3xl shadow-lg overflow-hidden relative"
        >
          <div className="p-8">
            <h1 className="text-4xl font-bold mb-8 text-gray-800">Fact Checker</h1>
            
            <motion.form
              onSubmit={handleSubmit}
              className="mb-8"
              whileHover={{ scale: 1.02 }}
              transition={{ type: "spring", stiffness: 300 }}
            >
              <label
                htmlFor="dropzone-file"
                className={`flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-2xl cursor-pointer bg-gray-50 hover:bg-gray-100 transition-all duration-300 ${isDragging ? 'border-blue-500 bg-blue-50' : ''}`}
                onDragEnter={handleDragEnter}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                onDrop={handleDrop}
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <CloudArrowUpIcon className={`w-12 h-12 mb-4 ${isDragging ? 'text-blue-500' : 'text-gray-400'}`} />
                  <p className="mb-2 text-sm text-gray-500"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500">MP4, PNG, JPG, JPEG, GIF (MAX. {MAX_UPLOAD_SIZE}MB)</p>
                </div>
                <input id="dropzone-file" type="file" className="hidden" onChange={handleFileChange} accept=".mp4,.png,.jpg,.jpeg,.gif" />
              </label>
              {file && <p className="mt-2 text-sm text-gray-600 font-medium">{file.name}</p>}
              <p className="mt-4 text-center text-gray-600">OR</p>
              <input 
                type="text" 
                className="mt-4 w-full py-3 px-4 border border-gray-300 rounded-xl shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
                placeholder="Enter Instagram Video Link"
                value={instagramLink}
                onChange={handleLinkChange}
                disabled={loading}
              />
              <motion.button
                type="submit"
                className="mt-4 w-full py-3 px-4 bg-gradient-to-r from-blue-500 to-purple-500 text-white font-semibold rounded-xl shadow-md hover:from-blue-600 hover:to-purple-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                disabled={(file === null && instagramLink === '') || loading}
              >
                {loading ? (
                  <span className="flex items-center justify-center">
                    <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                    </svg>
                    {['Uploading...', 'Processing...', 'Analyzing...'][stage - 1]}
                  </span>
                ) : 'Upload and Process'}
              </motion.button>
            </motion.form>

            {error && (
              <motion.div
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                className="bg-red-100 border-l-4 border-red-500 text-red-700 p-4 mb-6 rounded-lg"
                role="alert"
              >
                <p>{error}</p>
              </motion.div>
            )}

            {result && (
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ delay: 0.2 }}
                className="space-y-6"
              >
                {result.image_analysis ? (
  <motion.div
    whileHover={{ scale: 1.02 }}
    className="bg-gray-50 rounded-2xl p-6 shadow-sm"
  >
    <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
      <PhotoIcon className="w-6 h-6 mr-2 text-blue-500" />
      Image Fact Check
    </h3>
    <div 
      className="bg-white p-4 rounded-xl overflow-auto  text-gray-700"
      dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.image_analysis) }}
    />
  </motion.div>
                ) : (
                  <>
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      className="bg-gray-50 rounded-2xl p-6 shadow-sm"
                    >
                      <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
                        <DocumentTextIcon className="w-6 h-6 mr-2 text-blue-500" />
                        Transcription
                      </h3>
                      <div className="bg-white p-4 rounded-xl overflow-auto text-gray-700">
                        {formatText(result.transcription)}
                      </div>
                    </motion.div>
                    <motion.div
                      whileHover={{ scale: 1.02 }}
                      className="bg-gray-50 rounded-2xl p-6 shadow-sm"
                    >
                      <h3 className="text-xl font-semibold mb-4 flex items-center text-gray-800">
                        <CheckCircleIcon className="w-6 h-6 mr-2 text-blue-500" />
                        Fact Check
                        {result.fact_check_html && (
                          <span className={`ml-2 px-3 py-1 text-xs font-semibold rounded-full ${getFactCheckStatus(extractStatusFromHtml(result.fact_check_html)).color}`}>
                            {getFactCheckStatus(extractStatusFromHtml(result.fact_check_html)).status}
                          </span>
                        )}
                      </h3>
                      {result.fact_check_html ? (
                        <div 
                          className="bg-white p-4 rounded-xl overflow-auto text-gray-700"
                          dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(result.fact_check_html) }}
                        />
                      ) : (
                        <p className="text-red-500">No fact check result available. Check console for details.</p>
                      )}
                    </motion.div>
                  </>
                )}
              </motion.div>
            )}
          </div>
        </motion.div>
      </AnimatePresence>
    </div>
  );
}

export default App;